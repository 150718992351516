const form = document.getElementById("gr-form");
const input = document.getElementById("gr-input");
const displayImg = document.getElementById("img-display");
const link = document.getElementById("gr-link");

form.addEventListener("submit", (e) => {
  e.preventDefault();
  const value = input.value;
  if (value === "") return;

  displayImg.src = `https://student.marwadiuniversity.ac.in:553/handler/getImage.ashx?SID=${value}`;
  link.href = `/view.html?gr=${value}`;

  console.log(displayImg.src);
});
